.container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  /* border: 1px solid red; */
  margin: 20px 0px;
}
.margin{
  margin: 10px 0px;
  padding: 0px 10px;
}
.margin2{
  margin: 20px 0px;
}
.margin3{
  margin: 40px 0px;
  padding: 0px 35px;
}