
.Background{
    width: 100%;
    height: 100vh;
    padding: 0;
    margin: 0;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    flex-direction: column;
}
.nav{
    width: 100%;
    height: 110px;
    background-color: var(--red);
}