
.main_container {
    width: 100%;
    height: 100vh;
    background-color: #ffffff;
}

.navbar {
    width: 100%;
    height: 100px;
    background-color: #FF6700;
    display: flex;
    justify-content: center;
    padding-top: 28px;
    box-sizing: border-box;
}

.container {
    width: 498px;
    height: 541px;
    background-color: #ffffff;
    border: 1px solid #ebebeb;
    margin: -16px auto 0 auto;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 48px;
    padding-bottom: 48px;
    box-sizing: border-box;
    justify-content: space-evenly;
}

.title {
    font-size: 26px;
    font-weight: 700;
    margin: 0;
    margin-top: 18.32px;
    margin-bottom: 18.32px;
}

.sub_title {
    font-size: 16px;
    margin: 0;
    padding: 0 64px;
    text-align: center;
}

.sub_title span {
    font-weight: 700;
}

.message {
    font-size: 14px;
    margin: 0;
    padding: 0 42px;
    text-align: center;
    margin-top: 16px;
}

.transaction_id {
    font-size: 15px;
    margin: 0;
    padding: 0 42px;
    font-weight: 700;
    text-align: center;
    margin-top: 4px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.goto_btn {
    background-color: #ffffff;
    box-shadow: 2px 2px 3px lightgray;
    padding: 12px 32px;
    margin-top: 24px;
    border: none;
    border: 1px solid #eeeeee;
    outline: none;
    color: #FF6700;
    font-size: 14px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: 700;
}

@media screen and (max-width: 498px){
    .container {
        width: 100%;
        height: 541px;
        background-color: #ffffff;
        border: 1px solid #ebebeb;
        margin: -16px auto 0 auto;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 48px;
        padding-bottom: 48px;
        box-sizing: border-box;
        justify-content: space-evenly;
    }
    .container #icon{
        width: 36%;
    }
    .sub_title {
        font-size: 16px;
        margin: 0;
        padding: 0 16px;
        text-align: center;
    }
}