.contents{
  flex: 1;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
  padding: 0px 20px;
}
.contents2{
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 0px 20px;
  margin: 20px 0px;
}
.iconContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.nextContainer{
  width:100%;
  margin-top: 10px;
}
.waitLoading{
  padding-top: 42px;
}
.auth_btn{
  background-color: #FF6700;
  border: none;
  outline: none;
  padding: 14px 24px;
  border-radius: 6px;
  color: white;
  font-weight: 500;
}
.text_sm{
  font-size: 13.33px;
  color: #777777;
  padding: 3px 54px;
}
.text_sm2{
  font-size: 13.33px;
  color: #777777;
  padding: 3px 20%;
}