
.contents{
  flex: 1;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
  padding: 0px 20px;
  z-index: 9999;
}
.contents2{
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 0px 40px;
  margin: 20px 0px;
  z-index: 9999;
}
.summaryItem{
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding: 5px 10px;
}
.w_full{
  width: 100%;
  background-color:#eef2f8;
  border-radius: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.accountBalance{
  background-color: rgba(255, 102, 52, 0.2);
  color: #FF6700;
  padding: 8px 12px;
  font-weight: 700;
  display:flex;
}
.selectAccount{
  border: 1px solid #FF6700;
  display: flex;
  position: relative;
  border-radius: 12px;
  width: 100%;
  margin-top: 16px;
  height: 75px;
}
.selectAccount span{
  margin-top: 24px;
  margin-left: 16px;
}
.accountDesc{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 12px;
}
.accountDesc h5{
  margin: 0;
  text-align: left;
}
.accountDesc p{
  margin: 0;
  text-align: left;
  font-size: 12px;
}
.dropdown{
  position: absolute;
  width: 100%;
  min-height: 12vh;
  background-color: #FFFFFF;
  box-shadow: 2px 2px 2px lightgray;
  border: 1px solid lightgray;
  border-radius: 12px;
  right: 0;
  top: 54px;
  margin: 0;
  padding: 0;
}
.dropdownItem{
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  padding: 5px 16px 8px 16px;
}
.dropdownItem:hover{
  background-color: #fbfbfb;
  cursor: pointer;
}
.dropdownItem h5{
  margin: 0;
  text-align: left;
  font-weight: 700;
}
.dropdownItem h5:nth-child(2){
  color: #FF4B01;
  font-size: 15px;
  font-weight: 700;
}
.dropdownItem p{
  margin: 0;
  font-size: 12px;
  text-align: left;
}
.continueBtn{
  display: flex;
  background-color: #FF6700;
  color: white;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  height: 52px;
  border: none;
  width: 100%;
  border-radius: 8px;
  font-weight: 500;
  margin-top: 12px;
  font-size: 13.33px;
}
.inputText{
  width: 100%;
  height: 54px;
  padding-left: 12px;
  margin-top: 12px;
  border: 1px solid #eef2f8;
}
.inputText:focus{
  outline: none;
}
.qr_container{
  background-color: #000000;
  padding: 16px 16px 10px 16px;
  border-radius: 16px;
  margin-top: 24px;
}
.qr_container img{
  border-radius: 4px;
}
.qr_container p{
  color: #f2f2f2;
  font-size: 28px;
  font-weight: lighter;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.error{
  background-color: #ffefef;
  color: #d13b00;
  text-align: left;
  font-size: 14px;
  display: flex;
  width: 100%;
  padding: 8px;
  word-break: break-all;
}
.pinInput{
  width: 100%;
  height: 48px;
  border: none;
  border: 1px solid #d5d5dd;
  margin-top: 12px;
  border-radius: 5px;
  padding-left: 12px;
}
.pinInput:focus{
  outline: none;
}
.otpModal h2{
  color: black;
}
.otpModal input {
  margin-bottom: 10px;
}
