.cardcontainer {
  background-color: var(--secondary);
  background-color: #ffffff;
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 300px 1fr;
  /* display: flex;
  justify-content: center;
  align-items: flex-start; */
  box-shadow: 2px 2px 12px #9c9c9c46;
}
@media screen and (max-width: 1000px){
  .cardcontainer {
    background-color: var(--secondary);
    background-color: #ffffff;
    width: 100%;
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    box-shadow: 2px 2px 12px #9c9c9c46;
  }
}
.side_card_container {
  width: 100%;
  height: 100%;
  padding: 32px 0px;
  /* border-left: 1px solid #000000; */
  /* justify-content: center; */
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 1px 0.5px #38383846;
}
.side_card_content {
  width: calc(100%);
  height: 100%;
  /* padding: 5px 15px; */
}

@media screen and (max-width:800px) {
  .cardcontainer{
    /* border: 1px solid red; */
    flex-direction: column;
    min-height: 600px;
  }
}
@media screen and (max-width:600px) {
  .cardcontainer{
    /* border: 1px solid red; */
    flex-direction: column;
    width: 100%;
  }
}