.container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
	/* background-color: var(--red); */
	/* padding: 14px 0px; */
	height: 70px;
	width: calc(100% - 10px);
	border-radius: 6px;
	color: #fff;
	/* border: 1px solid red; */
	box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.2);
	margin: 15px 0px;
}
.container:hover {
	background-color: aliceblue;
	cursor: pointer;
}
.boxContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
}
.logoContainer {
	padding: 0px 10px;
}
.textContainer {
	/* border: 1px solid red; */
	flex: 1;
}

.play {
	padding-right: 10px;
}
