.text {
  font-family: "Lato";
  font-style: Regular;
  font-size: 12px;
  line-height: 15px;
  /* line-height: 100%; */
  /* Align: Left */
  /* Vertical align: Top */
  text-align: left;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 2px;
}
