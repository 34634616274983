.title {
  font-style: Bolder;
  font-size: 15px;
  line-height: 20px;
  line-height: 100%;
  font-weight: 600;
  /* border: 1px solid red; */
  /* width: 100%; */
  text-align: left;
  margin-left: 21px;
  padding: 10px 0;
  margin-bottom: 15px;
  /* padding: 0px 20px; */
  /* Align: Left */
  /* Vertical align: Top */
}
