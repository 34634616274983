.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* background-color: var(--red); */
  padding: 10px 0px;
  width: calc(100% - 10px);
  border-radius: 5px;
  color: #fff;
}
.boxContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 24px;
}
