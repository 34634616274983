.text {
  font-family: DM Sans;
  font-style: Regular;
  font-size: 16px;
  line-height: 20px;
  line-height: 100%;
  /* Align: Left */
  /* Vertical align: Top */
  text-align: left;
  /* color: rgba(0, 0, 0, 0.6); */
  font-weight: 800;
  color: #000000;
}
.currency {
  border-bottom: 3px solid var(--red);
  margin-right: 2px;
}
.input {
  background: none;
  border: none;
  border: 1px solid #CCCCCC;
  width: 40px;
  height: 40px;
  /* border-radius: 10px; */
  text-align: center;
  outline: 0;
}
.input2 {
  background: none;
  border: none;
  width: 100%;
  /* height: 30px; */
  text-align: left;
  padding-left: 16px;
  margin-top: 16px;
  margin-bottom: -4px;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  letter-spacing: 0.1px;
  outline: 0;
  font-family: 'Inconsolata';
}
input::placeholder {
  color: rgba(0, 0, 0, 0.43);
  font-family: "Lato";
  font-style: normal;
  font-size: 13px;
  line-height: 20px;
  line-height: 100%;
}
.container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 5px 0px;
  position: relative;
}
.input_container {
  /* width: calc(100% - 40px); */
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.15);
  /* padding: 5px 15px; */
  border-radius: 4px;
  margin: 10px 0px;
  position: relative;
}
.select{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.code {
  position: absolute;
  top: 40%;
  right: 0;
  font-size: 9px;
  margin-right: 80px;
}
.code code {
  background-color: rgba(221, 215, 215, 0.32);
}
.img {
  position: absolute;
  right: 0;
  top: 12px;
  margin-right: 6px;
  /* width: 75px;
  height: 20px; */
  margin-top: 5px;
  image-rendering: -moz-crisp-edges;
  image-rendering:   -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
.errorlabel{
  position: absolute;
  bottom: -20px;
  left: 16px;
  color: red;
  font-style: italic;
  font-size: 12px;
}
.inline_label{
  position: absolute;
  bottom: 42px;
  display: block;
  font-size: 11px;
  color: #ff0000f5;
  font-weight: medium;
  left: 16px;
}
