@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@300,400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300,400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;500&display=swap');


*{
  --red:#FF6700;
  --secondary:#E5E5E5;
  --secondsecondary:#fff3f380;
  --select-border: #777;
  --select-focus: blue;
  --select-arrow: var(--select-border);
}
body {
  padding: 0;
  margin: 0;
  font-family: 'Lato';
  /* 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 background-color: var(--secondary);
 box-sizing: border-box;
}

*{
  /* font-family: "Lato"; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
select{
  
}
