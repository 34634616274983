.container {
  /* height: 100vh; */
  width: 800px;
  max-width: 1200px;
  position: absolute;
  top: 0;
  z-index: 10;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.controls {
  width: 100%;
  height: 85px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.bottom {
  height: 150px;
  width: 100%;
  margin: 20px 0px;
  background-color: #fff;
  box-shadow: 0px 0.5px 1px 0.9px #55545446;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.logs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}
.width {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10%;
}
.logostyles {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.secondOption {
  display: none;
}
.cancelBtn{
  background-color: transparent;
  border: 1px solid #ffffff;
  color: #ffffff;
  padding: 12px 24px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.cancelBtn:hover{
  background-color: #ff0000ab;
  color: #F6EFEF;
}

@media screen and (max-width: 1000px){
  .bottom {
    height: 150px;
    width: 100%;
    margin: 20px 0px;
    background-color: #fff;
    box-shadow: 0px 0.5px 1px 0.9px #55545446;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 840px){
  .container{
    padding: 0 36px;
    width: 100%;
  }
}
@media screen and (max-width: 800px) {
  .hidden {
    display: none;
  }
  .secondOption {
    display: flex;
    width: calc(100%);
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgb(168, 161, 161);
  }
  .eachMobileMenu{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 20px;

  }
  .active{
    background-color: #F6EFEF;
  }
}

@media screen and (max-width: 600px) {
  .bottom {
    width: 100%;
  }
  .container{
    padding: 0 4px;
    width: 100%;
  }
}
@media screen and (max-width: 450px) {
  .container {
    width: 95%;
  }
}
