.text {
  font-family: "Lato";
  font-style: Regular;
  font-size: 16px;
  line-height: 20px;
  line-height: 100%;
  /* Align: Left */
  /* Vertical align: Top */
  text-align: left;
  /* color: rgba(0, 0, 0, 0.6); */
  font-weight: 800;
  color: #000000;
}
.currency{
  border-bottom: 3px solid var(--red);
  margin-right:2px ;

}