.textContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 15px;
  width: calc(100%-30px);
}
.prize {
  padding: 0px 15px;
  display: flex;
  justify-content: space-between;
  width: calc(100%-30px);
  margin-bottom: 25px;
}
.content {
  padding: 0px 20px;
}
.inputSecondContainer {
  display: flex;
  justify-content: flex-start;
  gap: 12px;
  align-items: center;
}
.shortInput {
  width: 50%;
}
.toughContainer {
  height: fit-content;
}
.general_cont {
  /* border: 1px solid red; */
  height: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  padding-bottom: 20px;
}
.forContainer {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 20px 60px;
  flex-direction: column;
}
.iswContainer {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 90%;
}
.subFourContainer {
  margin-top: 20px;
  margin-bottom: 50px;
}
.handlebutton {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}
