.text {
  font-style: Regular;
  font-size: 12px;
  line-height: 20px;
  line-height: 100%;
  /* Align: Left */
  /* Vertical align: Top */
  text-align: left;
  /* color: rgba(0, 0, 0, 0.6); */
  font-weight: 800;
  color: #000000;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--red);
  padding: 18px 0px;
  /* width: calc(100% - 36px); */
  width: 100%;
  border-radius: 5px;
  border-color: var(--red);
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
}
.cancel {
  border: solid 1px #e0e0e0;
  color: #828282;
  padding: 5px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin-top: 15px;
  font-size: 12px;
  font-weight: 600;
}
.cancel:hover {
  background-color: #e8e7e7;
  color: #828282;
}
