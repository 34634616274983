.text {
  font-family: "Lato";
  font-style: normal;
  font-size: 15px;
  line-height: 20px;
  line-height: 100%;
  /* Align: Left */
  /* Vertical align: Top */
  text-align: left;
  color: #000;
  font-weight: 400;
}
