
.contents2{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-color: #FFECE5;
  border-radius: 50%;
  /* border:1px solid red; */
}





