.SectionItem {
  width: 100%;
  height: 60px;
  cursor: pointer;
  justify-content: center;
  display: flex;
  align-items: center;
  border-top: 1px solid #F6EFEF;
}
.SectionItem:hover {
  background-color: var(--secondsecondary);
}

.names {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
  height: 100%;
  font-size: 15px;
  font-weight: 600;
  font-style: normal;
}
.title {
  /* font-family: DM Sans; */
  font-style: Bolder;
  font-size: 15px;
  line-height: 20px;
  line-height: 100%;
  margin: 2px 0px;

}
.description {
  /* font-family: DM Sans; */
  font-style: "Normal";
  font-size: 13px;
  text-align: left;
  line-height: 17px;
  line-height: 100%;
  margin: 2px 0px;
  font-weight: lighter !important;
}
.icon {
  font-size: 24px;
  color: var(--red);
  padding: 0px 25px;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
}
.SectionItem.active{
  color: red;
}