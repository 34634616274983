.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  /* background-color: var(--red); */
  color: #fff;
  /* border: 1px solid red; */
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9999;
}
