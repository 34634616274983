.overlay{
	width: 100%;
	height: 100vh;
	position: fixed;
	z-index: 100;
	background-color: rgba(0, 0, 0, 0.4);
	-webkit-backdrop-filter: blur(5px);
	backdrop-filter: blur(5px);
	display: flex;
	align-items: center;
	justify-content: center;
}

.overlayInner{
	width: 400px;
	height: 320px;
	background-color: #FFFFFF;
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 32px;
}

.overlayInner .title{
	font-weight: 700;
	font-size: 20px;
	margin-top: 12px;
}

.overlayInner .button2{
	padding: 12px 18px;
	background-color: white;
	border: 1px solid #eee;
	outline: none;
	box-shadow: 2px 3px 6px #fafafa;
	width: 150px;
	border-radius: 6px;
	color: #FF6700;
	transition: all 400ms ease-in-out;
}
.overlayInner .button2:hover{
	box-shadow: 3px 16px 16px #eee;
	transition: all 400ms ease-in-out;
}

.overlayInner p{
	width: 85%;
}
.container {
	width: 100%;
	min-height: 100vh;
	background-color: #f5f5f5;
	padding-bottom: 24px;
}
.navbar {
	width: 100%;
	height: 67px;
	background-color: #ff6700;
	display: flex;
	align-items: center;
	justify-content: center;
}
.profileContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
.profileContainer .img {
	width: 72px;
	height: 72px;
	border-radius: 50%;
	background-color: #000000;
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 24px 0;
}
.h5{
	font-size: 20px;
	font-weight: 500;
	margin: 0;
}
.title{
	font-size: 17px;
	font-weight: 500;
	margin: 8px 0;
}
.p{
	font-size: 14px;
	color: #555555;
	margin: 0;
	font-weight: 700;
	margin-bottom: 8px;
}
.formContainer{
	width: 64%;
	background-color: #ffffff;
	height: auto;
	margin: auto;
	padding: 24px 0 32px 0;
	border: 1px solid #d5d5d5;
	border-radius: 6px;
}
.form2Group{	
	display: grid;
	grid-template-columns: 1fr 1fr;
	margin-top: 12px;
	margin-bottom: 12px;
}
.formGroup{	
	display: grid;
	grid-template-columns: 1fr;
	margin-top: 12px;
	margin-bottom: 12px;
}
.inputWrap{
	width: 100%;
	padding: 6px 24px;
}
.label{
	font-size: 14.5px;
	text-align: left;
	display: block;
	margin-bottom: 4px;
}
.input{
	display: flex;
	width: 100%;
	height: 40px;
	background-color: #FFFFFF;
	border: none;
	outline: none;
	border: 1px solid #d5d5d5;
	padding-left: 12px;
}
.button{
	width: 100%;
	height: 48px;
	color: #ffffff;
	background-color: #FF6700;
	border-radius: 4px;
	outline: none;
	border: none;
}
.h6{
	font-size: 12px;
	margin: 0;
}
.h6 a{
	color: #1111DD;
}
.fieldset{
	border-radius: 10px; 
	border-color: #FF6700; 
	padding: 10px;
	margin-bottom: 15px;
}
.error {
	color: #FF6700;
	text-align: left;
	display: block;
}

@media screen and (max-width: 700px){
	.formContainer{
		width: 90%;
	}
}

@media screen and (max-width: 600px){
	.form2Group{
		grid-template-columns: 1fr;
	}
	.title{
		width: 70%;
	}
}