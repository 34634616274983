.formHead{
    width: 640px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding-top: 6vh;
}
.formHead *{
    font-weight: 400;
}
.title{
    font-size: 42px;
    font-weight: 700;
    margin: 0;
    margin-top: 24px;
    color: #022341;
}
.subTitle{
    font-size: 21px;
    color: #011c34;
    margin: 0;
    margin-top: 16px;
    font-weight: 400;
}
.subTitle b{
    font-weight: 700;
}
.formBody{
    background-color: #011c34;
    width: 640px;
    min-height: 48vh;
    padding-top: 0;
    padding-bottom: 0px;
    margin: auto;
    margin-top: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
}
.container{
    width: 100%;
    position: relative;
}
.input {
    background: none;
    border: none;
    border: 1px solid #042b50;
    width: 100%;
    max-width: 100%;
    height: 54px;
    margin-top: 16px;
    background-color: #09223C;
    padding-top: 24px;
    padding-left: 12px;
    font-size: 18px;
    font-weight: 500;
    color: #eeeeee;
    letter-spacing: 0.1px;
    outline: 0;
    font-family: 'Inconsolata';
    border-radius: 4px;
  }
  .input2 {
    background: none;
    border: none;
    border: 1px solid #042b50;
    width: 100%;
    max-width: 100%;
    height: 100px;
    margin-top: 16px;
    background-color: #09223C;
    padding-top: 28px;
    padding-left: 12px;
    font-size: 18px;
    font-weight: 500;
    color: #eeeeee;
    letter-spacing: 0.1px;
    outline: 0;
    font-family: 'Inconsolata';
    border-radius: 4px;
  }
  .inputGroup2{
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 100%;
      column-gap: 24px;
      padding-left: 24px;
      padding-right: 24px;
  }
  .inputGroup{
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    column-gap: 24px;
    padding-left: 24px;
    padding-right: 24px;
}
  .inline_label{
    position: absolute;
    bottom: 30px;
    display: block;
    font-size: 12.25px;
    letter-spacing: 0.25px;
    color: rgba(255,255,255,0.5);
    font-weight: 500;
    left: 12px;
    text-transform: uppercase;
  }
  .inline_label2{
    position: absolute;
    top: 25px;
    display: block;
    font-size: 12.25px;
    letter-spacing: 0.25px;
    color: rgba(255,255,255,0.5);
    font-weight: 500;
    left: 12px;
    text-transform: uppercase;
  }
  .button{
    background-color: #fd8383;
    margin-top: 12px;
    color: #011c34;
    font-size: 15px;
    text-transform: uppercase;
    border: none;
    outline: none;
    width: 50%;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    border-radius: 4px;
    font-family: 'Inconsolata';
  }
.button.active{
    background-color: #f81111;
    color: #eeeeee;
}

@media screen and (max-width: 600px){
  .formHead,
  .formBody{
    width: 100%;
    padding: 48px 16px;
  }
  .inputGroup2{
    grid-template-columns: 1fr;

  }
}