.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  padding: 10px 0px;
  width: 350px;
  height: 400px;
  border-radius: 10px;
  /* border: 1px solid red; */
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.2);
  position: relative;
}
.cancel {
  position: absolute;
  top: 0;
  right: 0;margin-right: -40px;
  cursor: pointer;
}
