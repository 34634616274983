

.App {
  text-align: center;
}

*{
  box-sizing: content-box;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.hidden{
  display: none;
}
.flex{
  display: flex;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input::placeholder{
  font-family: "Lato";
}
button{
  cursor: pointer;
}


/* Styles for loading svg */
.spinner{
  width: 18px;
  height: 18px;
  background-color: transparent;
  border: 2.5px solid #ffffff;
  margin-right: 8px;
  border-radius: 50%;
  border-top: 2.5px solid transparent;
  animation: 800ms spin linear infinite forwards;
}
.spinner2{
  width: 18px;
  height: 18px;
  background-color: transparent;
  border: 2.5px solid #FF6700;
  margin-right: 8px;
  border-radius: 50%;
  border-top: 2.5px solid transparent;
  animation: 800ms spin linear infinite forwards;
}
@-webkit-keyframes spin{
  from{
    transform: rotate(0deg);
  }to{
    transform: rotate(360deg);
  }
}
@keyframes spin{
  from{
    transform: rotate(0deg);
  }to{
    transform: rotate(360deg);
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}