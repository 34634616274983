.title {
  font-family: DM Sans;
  font-style: Bolder;
  font-size: 15px;
  line-height: 20px;
  line-height: 100%;
  font-weight: 800;
  /* border: 1px solid red; */
  /* width: 100%; */
  text-align: left;
  margin-left: 21px;
  padding: 10px 0;
  margin-bottom: 15px;
  /* padding: 0px 20px; */
  /* Align: Left */
  /* Vertical align: Top */
}
.contents{
  flex: 1;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
  padding: 0px 20px;
}
.inputContainer{
  margin-top: 30px;
}
.buttonContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 50px;
}
.contentsBetween{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  flex: 1;
  /* border: 1px solid red; */
  padding: 0px 25px;
}
.text{
  /* text-align: center;
   */
   width: 60%;
   margin: 10px 0px;
   line-height: 50px;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
}
.inputContainer2{
  width: 98%;
 
}
.buttonContainer2{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
}
.inputContainer3{
  /* justify-content: center; */
  /* align-items: center; */
  display: flex;

}
.inputContainer4{
  width: 99%;
  margin-left: 2px;
}